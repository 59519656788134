import React, {
	type KeyboardEvent,
	type MouseEventHandler,
	type ReactNode,
	useCallback,
	useRef,
} from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import Button, { IconButton } from '@atlaskit/button/new';
import MoreIcon from '@atlaskit/icon/glyph/more';
import { type PopupProps } from '@atlaskit/popup';
import { Inline } from '@atlaskit/primitives';

import { useLoomCrossFlowRequestAccessCallback } from '../useLoomCrossFlowRequestAccessCallback';

import { BaseLoomPopupCard, type TriggerProps as BaseTriggerProps } from './BaseLoomPopupCard';
import LoomCrossSellPopupCardImage from './images/LoomCrossSellPopupCardImage.png';

const i18n = defineMessages({
	tryNow: {
		id: 'loom-utils.loom-cross-sell-popup-card.tryNow',
		defaultMessage: 'Try now',
		description: 'text on button that launches a cross-sell flow for Loom',
	},
});

type TriggerProps = BaseTriggerProps & {
	onClick: MouseEventHandler;
};

type LoomCrossFlowPopupCardProps = {
	description: string;
	placement?: PopupProps['placement'];
	shouldRenderToParent?: boolean;
	// a unique identifier for the entrypoint
	sourceComponent: string;
	title: string;
	trigger: (triggerProps: TriggerProps) => ReactNode;
};

export const LoomCrossFlowPopupCard: React.FC<LoomCrossFlowPopupCardProps> = ({
	description,
	placement,
	shouldRenderToParent,
	sourceComponent,
	title,
	trigger,
}) => {
	const intl = useIntl();
	const ctaRef = useRef<HTMLButtonElement | null>(null);

	// TODO: https://hello.jira.atlassian.cloud/browse/CTIA-2511
	const triggerAdControlMenu = useCallback(() => {
		// eslint-disable-next-line no-console
		console.log('DEBUG: Triggering ad-controls menu');
	}, []);

	const triggerCrossFlow = useLoomCrossFlowRequestAccessCallback({ sourceComponent });

	return (
		<BaseLoomPopupCard
			actions={
				<Inline space="space.100">
					<IconButton appearance="subtle" icon={MoreIcon} label="" onClick={triggerAdControlMenu} />
					<Button appearance="discovery" onClick={triggerCrossFlow} ref={ctaRef}>
						{intl.formatMessage(i18n.tryNow)}
					</Button>
				</Inline>
			}
			description={description}
			imageSrc={LoomCrossSellPopupCardImage}
			placement={placement}
			shouldRenderToParent={shouldRenderToParent}
			title={title}
			trigger={(popupTriggerProps) =>
				trigger({
					...popupTriggerProps,
					onClick: triggerCrossFlow,
					onKeyDown: (e: KeyboardEvent) => {
						if (e.key === 'Tab') {
							ctaRef.current?.focus();
						}
					},
				})
			}
		/>
	);
};
