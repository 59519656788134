import type { FC } from 'react';
import React, { useRef, useEffect } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';
// We have deprecated unstated. Please use react-sweet-state instead
// eslint-disable-next-line no-restricted-imports
import { Subscribe } from 'unstated';

import Button from '@atlaskit/button';

import { HeaderItemsContainer, HEADER_ITEM_KEYS } from '@confluence/header-items-manager';
import { Attribution, withErrorBoundary } from '@confluence/error-boundary';
import {
	LoomCrossFlowPopupCard,
	LoomCrossJoinPopupCard,
	useLoomEntryPointVariant,
} from '@confluence/loom-utils';
import { useBooleanFeatureFlag } from '@confluence/session-data';
import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';

import { LoomIcon } from './LoomIcon';
import { PageHeaderLoomButton } from './PageHeaderLoomButton';

const i18n = defineMessages({
	crossJoinEntryPointLabel: {
		id: 'page-header-loom-button.crossJoinEntryPointLabel',
		defaultMessage: 'Join your team on Loom to record a video',
		description:
			'The a11y-friendly ARIA label for the Loom button in the page header menu when a user is eligible for cross-join',
	},
	crossSellPopupCardDescription: {
		id: 'page-header-loom-button.crossSellPopupCardDescription',
		defaultMessage:
			'Save time and engage your team with a video walkthrough, demo, update, and more.',
		description: 'Description inside a popup card advertising an opportunity to try Loom',
	},
	crossSellPopupCardTitle: {
		id: 'page-header-loom-button.crossSellPopupCardTitle',
		defaultMessage: 'Share your work with a video',
		description: 'Title of a popup card advertising an opportunity to try Loom',
	},
	entryPointLabel: {
		id: 'page-header-loom-button.entryPointLabel',
		defaultMessage: 'Record a Loom video',
		description:
			'The a11y-friendly ARIA label and tooltip for the button in the page header to record a Loom video.',
	},
});

type PageHeaderLoomEntryPointComponentProps = {
	contentId: string;
	spaceKey: string;
	contentType: string;
};

const PageHeaderLoomEntryPointComponent: FC<PageHeaderLoomEntryPointComponentProps> = ({
	contentId,
	spaceKey,
	contentType,
}) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	const isItemMarked = useRef(false);
	const markItemRef = useRef<((item: HEADER_ITEM_KEYS) => void) | undefined>();

	const entryPointVariant = useLoomEntryPointVariant();
	const isEntryPointEnabled = ['BETA', 'CO_USE', 'CROSS_FLOW', 'CROSS_JOIN'].includes(
		entryPointVariant,
	);

	const intl = useIntl();

	const isPageHeaderLoomButtonEnabled = useBooleanFeatureFlag(
		'confluence.frontend.loom-entrypoint-page-header_zvj58',
	);

	const shouldRenderButton = isEntryPointEnabled && isPageHeaderLoomButtonEnabled;

	useEffect(() => {
		if (shouldRenderButton && !isItemMarked.current && markItemRef.current) {
			isItemMarked.current = true;
			markItemRef.current(HEADER_ITEM_KEYS.RECORD_LOOM);
		}
	}, [shouldRenderButton]);

	if (!shouldRenderButton) {
		return null;
	}

	return (
		<Subscribe to={[HeaderItemsContainer]}>
			{({ addItem, markItem, getAllItemsLoadState }: HeaderItemsContainer) => {
				addItem(HEADER_ITEM_KEYS.RECORD_LOOM);
				markItemRef.current = markItem;

				if (entryPointVariant === 'BETA' || entryPointVariant === 'CO_USE') {
					// getAllItemsLoadState() is important because we want to wait until the entire header button group has been rendered
					// before we show the spotlight, so we don't end up with UI shifting and the spotlight icon appearing
					// over another button
					const isHeaderFinishedLoading = getAllItemsLoadState();
					return (
						<PageHeaderLoomButton
							canRenderSpotlight={isHeaderFinishedLoading}
							contentId={contentId}
							contentType={contentType}
							dataVC="loom-button"
							label={intl.formatMessage(i18n.entryPointLabel)}
							spaceKey={spaceKey}
							ssrPlaceholderIdProp={ssrPlaceholderIdProp}
						/>
					);
				}

				if (entryPointVariant === 'CROSS_FLOW') {
					return (
						<LoomCrossFlowPopupCard
							description={intl.formatMessage(i18n.crossSellPopupCardDescription)}
							sourceComponent="loom-confluence-page-header"
							title={intl.formatMessage(i18n.crossSellPopupCardTitle)}
							trigger={(triggerProps) => (
								<Button
									{...triggerProps}
									appearance="subtle"
									aria-label={intl.formatMessage(i18n.entryPointLabel)}
									data-vc="loom-button"
									iconAfter={<LoomIcon label="" size="medium" />}
									{...ssrPlaceholderIdProp}
								/>
							)}
						/>
					);
				}

				if (entryPointVariant === 'CROSS_JOIN') {
					return (
						<LoomCrossJoinPopupCard
							description={intl.formatMessage(i18n.crossSellPopupCardDescription)}
							title={intl.formatMessage(i18n.crossSellPopupCardTitle)}
							triggerLink={(href, triggerProps) => (
								<Button
									{...triggerProps}
									appearance="subtle"
									aria-label={intl.formatMessage(i18n.crossJoinEntryPointLabel)}
									data-vc="loom-button"
									href={href}
									iconAfter={<LoomIcon label="" size="medium" />}
									target="_blank"
									{...ssrPlaceholderIdProp}
								/>
							)}
						/>
					);
				}

				// This condition currently isn't possible, but explicitly handling it here in case the
				// logic defining `isEntryPointEnabled` changes without updating this rendering behavior
				return null;
			}}
		</Subscribe>
	);
};

export const PageHeaderLoomEntryPoint = withErrorBoundary({
	attribution: Attribution.CONTENT_TYPES,
})(PageHeaderLoomEntryPointComponent);
