import React, { type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl-next';

import { LinkButton } from '@atlaskit/button/new';
import { type PopupProps } from '@atlaskit/popup';

import { useLoomCrossJoinUrl } from '../useLoomCrossJoinUrl';

import { BaseLoomPopupCard, type TriggerProps } from './BaseLoomPopupCard';
import LoomCrossSellPopupCardImage from './images/LoomCrossSellPopupCardImage.png';

const i18n = defineMessages({
	crossJoinCTA: {
		id: 'loom-utils.loom-cross-sell-popup-card.crossJoinCTA',
		defaultMessage: 'Try now',
		description: 'text on button that launches a cross-join flow for Loom',
	},
});

type LoomCrossJoinPopupCardProps = {
	description: string;
	placement?: PopupProps['placement'];
	shouldRenderToParent?: boolean;
	title: string;
	triggerLink: (href: string, triggerProps: TriggerProps) => ReactNode;
};

export const LoomCrossJoinPopupCard: React.FC<LoomCrossJoinPopupCardProps> = ({
	description,
	placement,
	shouldRenderToParent,
	title,
	triggerLink,
}) => {
	const intl = useIntl();

	const crossJoinHref = useLoomCrossJoinUrl();

	return (
		<BaseLoomPopupCard
			actions={
				<LinkButton appearance="discovery" href={crossJoinHref} target="_blank">
					{intl.formatMessage(i18n.crossJoinCTA)}
				</LinkButton>
			}
			description={description}
			imageSrc={LoomCrossSellPopupCardImage}
			placement={placement}
			shouldRenderToParent={shouldRenderToParent}
			title={title}
			trigger={(popupTriggerProps) => triggerLink(crossJoinHref, popupTriggerProps)}
		/>
	);
};
