import { useCallback } from 'react';
import { useCrossFlow, Journeys, Targets } from '@atlassiansox/cross-flow-support';

type CrossFlowRequestAccessOptions = {
	sourceComponent: string;
};

export const useLoomCrossFlowRequestAccessCallback = ({
	sourceComponent,
}: CrossFlowRequestAccessOptions) => {
	const crossFlow = useCrossFlow();
	const onRequestAccess = useCallback(async () => {
		if (crossFlow.isEnabled) {
			const { success } = await crossFlow.api.open({
				journey: Journeys.GET_STARTED,
				sourceComponent,
				sourceContext: 'confluence',
				targetProduct: Targets.LOOM,
			});

			/**
			 * `success` only indicates whether or not the user closed the drawer via the "back arrow"
			 * button. It does *not* indicate that the user succesfully completed cross-flow, however
			 * *if* the user goes through cross-flow, it *will* be `true`
			 */
			if (success) {
				// TODO: re-check Loom provisioning status + update entrypoints + (if co-use) open SDK
				// https://hello.jira.atlassian.cloud/browse/CTIA-2677
			}
		}
	}, [crossFlow, sourceComponent]);
	return onRequestAccess;
};
